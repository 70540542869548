<template>
  <Layout>
    <div class="container-study-intro">
      <div>
        <h2>Tenha um Cronograma de Estudos totalmente personalizado.</h2>
        <p>
          Com base no curso desejado e na sua disponibilidade de tempo,
          criaremos um plano de estudos exclusivo com todas as videoaulas e
          exercícios que você realmente precisa.
        </p>
        <ButtonPlay type="normal" @click="goToCreation">
          <div class="icon-button">
            <IconSax type="outline" name="calendar" />
            Fazer meu cronograma
          </div>
        </ButtonPlay>
      </div>
      <div>
        <h2>Foque no que você precisa estudar.</h2>
        <p>
          Nós montamos um cronograma que se ajusta à sua rotina de estudos
          durante todo o ano, sempre priorizando seu tempo e disponibilidade.
        </p>
        <ButtonPlay type="normal">
          <div class="icon-button">Experimente agora</div>
        </ButtonPlay>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  name: "StudyScheduleIntroduction",
  components: {
    Layout: () => import("@/layout/Layout.vue"),
    ButtonPlay: () => import("@/components/common/ButtonPlay.vue"),
  },
  methods: {
    goToCreation() {
      this.$router.push("/cronograma/criacao");
    },
  },
};
</script>

<style lang="scss" scoped>
.container-study-intro {
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 8.31rem;
  gap: 5.5rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--fontcolor);
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--fontcolor);
  }
}

::v-deep .svgCss {
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  svg path {
    fill: var(--neutral-gray-800) !important;
  }
}

.icon-button {
  @include flex-row;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;

  ::v-deep .svgCss {
    svg {
      width: 1rem;
      height: 1rem;
    }

    svg path {
      fill: #fff !important;
    }
  }
}
</style>
